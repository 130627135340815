import React, { useGlobal, useEffect, useRef } from 'reactn';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { FaArrowRight, FaBullhorn } from 'react-icons/fa';
import { GatsbyImage, IGatsbyImageData, getImage } from 'gatsby-plugin-image';
import OutboundLink from '../common/OutboundLink';
import { useContext } from 'react';
import { GlobalContext } from '../../context/GlobalContext';

const BUILD_ID = process.env.GATSBY_BUILD_ID || 'dev';

const BannerAnnouncement = () => {
  const { bannerHeight, setBannerHeight } = useContext(GlobalContext);
  const [isEmployer] = useGlobal<{ isEmployer: boolean }>('isEmployer');
  const [isStaff] = useGlobal<{ isStaff: boolean }>('isStaff');
  const data = useStaticQuery<{
    allContentfulBannerAnnouncement: {
      edges: {
        node: BannerAnnouncementType;
      }[];
    };
  }>(contentQuery);
  const bannerContent = data.allContentfulBannerAnnouncement.edges.filter(
    (edge) =>
      edge.node.buildEnvironments.includes(BUILD_ID) &&
      (isEmployer
        ? edge.node.userRole === 'employer'
        : isStaff
          ? edge.node.userRole === 'staff'
          : edge.node.userRole === 'unauthenticated')
  )[0]?.node;

  const announcementBannerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (
      !!announcementBannerRef.current &&
      announcementBannerRef.current?.offsetHeight > 0 &&
      announcementBannerRef.current?.offsetHeight !== bannerHeight
    ) {
      setBannerHeight(announcementBannerRef.current?.offsetHeight);
    } else if (!bannerContent && bannerHeight !== 0) {
      setBannerHeight(0);
    }
  }, [announcementBannerRef.current?.offsetHeight]);

  // const [viewedAnnoucementIds, setViewedAnnouncementId] = useLocalStorageState<
  //   string[]
  // >('viewedAnnoucementIds', { defaultValue: [] });

  // function toggleBanner(bannerId: string) {
  //   setViewedAnnouncementId([...viewedAnnoucementIds, bannerId]);
  // }

  if (!bannerContent) {
    return null;
  }

  const image = () => {
    {
      const image = getImage(bannerContent.icon?.gatsbyImageData);
      if (image) {
        return (
          <div style={{ width: '35px', height: '35px' }}>
            <GatsbyImage
              loading="eager"
              image={image}
              alt={bannerContent.icon.title}
            />
          </div>
        );
      } else {
        return <FaBullhorn className="fa-2x" />;
      }
    }
  };

  return (
    <div
      style={{
        backgroundColor: bannerContent?.backgroundColor,
      }}
    >
      <div
        className="d-flex flex-wrap gap-2 px-2 justify-content-center align-items-center py-2"
        ref={announcementBannerRef}
        id="announcement-banner"
      >
        <div className={`text-dark col-auto mb-0 d-flex align-items-center`}>
          {image()}
        </div>
        <h5
          className="d-none d-lg-flex mb-0 text-center text-truncate fa-m"
          style={{ maxWidth: 'calc(100% - 50px)' }}
        >
          {bannerContent.heading}
        </h5>
        <p
          className="d-lg-none mb-0 text-center fa-m fw-bold"
          style={{ maxWidth: 'calc(100% - 50px)' }}
        >
          {bannerContent.heading}
        </p>
        {bannerContent.ctaLink.includes('dentalpost.net') && (
          <Link
            to={bannerContent.ctaLink.replace(/.*\/\/[^\/]*/, '')}
            className="col-auto mx-5"
          >
            {bannerContent.ctaText} <FaArrowRight />
          </Link>
        )}
        {!!bannerContent &&
          !bannerContent.ctaLink.includes('dentalpost.net') && (
            <OutboundLink href={bannerContent.ctaLink} className="col-auto">
              {bannerContent.ctaText} <FaArrowRight />
            </OutboundLink>
          )}
      </div>
    </div>
  );
};

export default BannerAnnouncement;

const contentQuery = graphql`
  query {
    allContentfulBannerAnnouncement {
      edges {
        node {
          backgroundColor
          ctaText
          id
          heading
          userRole
          ctaLink
          buildEnvironments
          icon {
            gatsbyImageData(width: 100, height: 100)
            title
          }
        }
      }
    }
  }
`;

export type BannerAnnouncementType = {
  backgroundColor: string;
  ctaText: string;
  id: string;
  heading: string;
  userRole: string;
  ctaLink: string;
  buildEnvironments: string;
  icon: {
    gatsbyImageData: IGatsbyImageData;
    title: string;
  };
};
