export const isWindowDefined = typeof window !== `undefined`;

export const scrollIntoView = (ref) => {
  if (isWindowDefined) {
    ref.scrollIntoView(true);
    return;
  } else {
    console.warn('Cannot scroll into view.');
  }
};

export const urlTitle = (props: {
  urltitle?: string;
  position: string;
  city: string;
  state: string;
}) => {
  const jobSlug = `${props.position}-job-in-${props.city}-${props.state}`;
  const title = props.urltitle ? props.urltitle : slugify(jobSlug);
  return title;
};

export const slugify = (string) => {
  // See these links:
  // https://medium.com/@mhagemann/the-ultimate-way-to-slugify-a-url-string-in-javascript-b8e4a0d849e1
  // https://gist.github.com/hagemann/382adfc57adbd5af078dc93feef01fe1
  const a = 'àáäâãåăæçèéëêǵḧìíïîḿńǹñòóöôœṕŕßśșțùúüûǘẃẍÿź·/_,:;';
  const b = 'aaaaaaaaceeeeghiiiimnnnoooooprssstuuuuuwxyz------';
  const p = new RegExp(a.split('').join('|'), 'g');

  return string
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w-]+/g, '') // Remove all non-word characters
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
};

export const getCookie = (cookieName) => {
  let cookieValue = document.cookie
    .split('; ')
    .find((row) => row.startsWith(cookieName));

  if (!!cookieValue) {
    return cookieValue.split('=')[1];
  } else {
    return null;
  }
};
