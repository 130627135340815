import React from 'react';
import { Col, Row, Container } from 'reactstrap';
import { FaEnvelope } from 'react-icons/fa';
import LeadCaptureForm from './LeadCaptureForm';

const LeadNewsletterSubscribe = (props: { displayLeadForm: boolean }) => {
  return props.displayLeadForm ? (
    <div className={`bg-solids-primary-green-10 text-center py-5`}>
      <Container>
        <Row className="justify-content-center">
          <Col
            xs="auto"
            className="mx-auto d-flex flex-column justify-content-center"
            style={{ width: '600px', maxWidth: '100%' }}
          >
            <div className="text-center">
              <FaEnvelope className="text-primary fa-3x" />
            </div>
            <h2 className="my-3">Stay In-The-Know</h2>
            <p>
              Don't miss exclusive offers, free career & hiring resources,
              dental industry events, news and reports, including DentalPost's
              annual Dental Salary Report.
            </p>
          </Col>
          <Col
            xs="auto"
            className="mx-auto d-flex flex-column flex-grow-1 justify-content-center"
          >
            <LeadCaptureForm
              formFields={[]}
              formId="footer-subscribe-form"
              formName="newsletter"
              cta="Subscribe Now"
              assetTitle="Footer - Newsletter Subscribe"
            />
          </Col>
        </Row>
      </Container>
    </div>
  ) : null;
};

export default LeadNewsletterSubscribe;
