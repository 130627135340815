import { AxiosResponse } from 'axios';
import React, { FormEvent, PropsWithChildren, useState } from 'react';
import { Form } from 'reactstrap';

const FormWrapper = (
  props: PropsWithChildren<{
    formId: string;
    onSubmit?: (
      e: FormEvent<HTMLFormElement> & { target: any }
    ) => Promise<AxiosResponse<any, any> | undefined>;
    onClick?: (props: any) => void;
    onReset?: () => void;
    handleSuccess?: (res: AxiosResponse) => void;
    disabled?: boolean;
  }>
) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (!!props.onSubmit) {
      setErrorMessage('');
      setIsSubmitting(true);
      props
        .onSubmit(e)
        .then((res) => {
          setIsSubmitting(false);
          if (!!props.handleSuccess && !!res) {
            props.handleSuccess(res);
          }
        })
        .catch((err) => {
          setErrorMessage(err.message);
        });
    } else if (!!props.onClick) {
      props.onClick(e);
    }
  }

  return (
    <Form
      id={props.formId}
      onSubmit={handleSubmit}
      method="post"
      onReset={!!props.onReset ? props.onReset : undefined}
    >
      <fieldset disabled={(isSubmitting || props.disabled) && !errorMessage}>
        {props.children}
      </fieldset>
      <div
        role="alert"
        id={`error-alert-${props.formId}`}
        className={`alert alert-danger mt-3 ${!!errorMessage ? 'd-flex' : 'd-none'}`}
      >
        {errorMessage}
      </div>
    </Form>
  );
};

export default FormWrapper;
