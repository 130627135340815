import React, { useState, useEffect, useContext } from 'react';
import { PropsWithChildren } from 'react';
import agent from '../agent';
import {
  UserContext,
  UserContextType,
  isEmployerType,
  isStaffType,
} from './UserContext';
import types from '../types/services-api';
import { OfficesContext, OfficesContextType } from './ProfileContext';
import { JobPostingCSVJobType, JobPostingCSVPartnerType } from '../types/csv';
import dayjs from 'dayjs';

export type JobSearchParamsType = {
  'days-old'?: string;
  endDate?: string;
  keyword?: string;
  position?: string;
  radius?: string;
  startDate?: string;
  type?: string;
  zip?: string;
  evaluation?: string;
};

type JobType = types.JobInternalViewType | types.PartnerJobDetailViewType;

export type JobSearchContextType = {
  defaultMapCenter: { lat: string; lng: string } | null;
  searchJobs: (searchParams: JobSearchParamsType) => void;
  localJobs: types.JobInternalViewType[] | undefined;
  nationalJobs: types.JobInternalViewType[] | undefined;
  partnerJobs: types.PartnerJobDetailViewType[] | undefined;
  updatePremiumJob: (updatedValues: types.JobInternalViewType) => void;
  updatePartnerJob: (updatedValues: types.PartnerJobDetailViewType) => void;
  searchErrorMessage: string;
  jobSearchParams: JobSearchParamsType | null;
  updateJobSearchParams: (updatedValues: JobSearchParamsType) => void;
};

export const JobSearchContext = React.createContext<JobSearchContextType>({
  defaultMapCenter: null,
  searchJobs: () => {},
  localJobs: undefined,
  nationalJobs: undefined,
  updatePremiumJob: () => {},
  partnerJobs: undefined,
  updatePartnerJob: () => {},
  searchErrorMessage: '',
  jobSearchParams: null,
  updateJobSearchParams: () => {},
});

const JobSearchContextWrapper = (props: PropsWithChildren) => {
  const { user } = useContext(UserContext) as UserContextType;
  const { primaryOffice } = useContext(OfficesContext) as OfficesContextType;
  const [localJobs, setLocalJobs] = useState<
    types.JobInternalViewType[] | undefined
  >(undefined);
  const [nationalJobs, setNationalJobs] = useState<
    types.JobInternalViewType[] | undefined
  >(undefined);
  const [partnerJobs, setPartnerJobs] = useState<
    types.PartnerJobDetailViewType[] | undefined
  >(undefined);
  const [searchErrorMessage, setSearchErrorMessage] = useState('');
  const [jobSearchParams, setJobSearchParams] = useState<JobSearchParamsType>({
    type: 'ANY',
  });
  const [defaultMapCenter, setDefaultMapCenter] = useState<{
    lat: string;
    lng: string;
  } | null>(null);

  useEffect(() => {
    if (isStaffType(user)) {
      setDefaultMapCenter({ lat: user.lat, lng: user.lng });
    } else if (isEmployerType(user) && !!primaryOffice) {
      setDefaultMapCenter({ lat: primaryOffice.lat, lng: primaryOffice.lng });
    }
  }, [user, primaryOffice]);

  useEffect(() => {
    if (
      !!jobSearchParams &&
      !!jobSearchParams.position &&
      !!jobSearchParams.zip &&
      jobSearchParams.zip !== 'undefined'
    ) {
      searchJobs();
    }
  }, [jobSearchParams]);

  function updateJobSearchParams(updatedValues: JobSearchParamsType) {
    setJobSearchParams({ ...jobSearchParams, ...updatedValues });
  }

  function searchJobs() {
    if (!!jobSearchParams.position && !!jobSearchParams.zip) {
      setLocalJobs(undefined);
      setNationalJobs(undefined);
      setPartnerJobs(undefined);
      setSearchErrorMessage('');
      //distance calculations will default to be from users lat/lng address instead of overall zip
      //only pass zip to api if different from their home address zip
      let transformedParams = {
        ...jobSearchParams,
        radius:
          jobSearchParams.radius === '101' ? '40' : jobSearchParams.radius,
      };
      if (isStaffType(user) && user.zip === jobSearchParams.zip) {
        transformedParams.zip = undefined;
      }
      agent.Jobs.search(transformedParams)
        .then((res) => {
          setLocalJobs(
            res.data.premiumJobs.filter((job) => {
              const evalFilter =
                jobSearchParams.evaluation === 'LIKED'
                  ? job.isLike
                  : jobSearchParams.evaluation === 'DISLIKED'
                    ? job.isDislike
                    : true;
              const startDateFilter = !!jobSearchParams.startDate
                ? job.jobDates.some(
                    (date) =>
                      !dayjs(date.date).isBefore(
                        dayjs(jobSearchParams.startDate)
                      )
                  )
                : true;
              const endDateFilter = !!jobSearchParams.endDate
                ? job.jobDates.some(
                    (date) =>
                      !dayjs(date.date).isAfter(dayjs(jobSearchParams.endDate))
                  )
                : true;
              return evalFilter && startDateFilter && endDateFilter;
            })
          );
          if (transformedParams.radius === '101') {
            setNationalJobs(
              res.data.premiumJobs.filter((job) => job.isNational)
            );
          } else {
            getNationalJobs();
          }
          setPartnerJobs(res.data.partnerJobs);
        })
        .catch((err) => {
          if (err && err.message) {
            setSearchErrorMessage(err.message);
          } else {
            setSearchErrorMessage('Failed to search for jobs');
          }
        });
    }
  }

  function getNationalJobs() {
    agent.Jobs.search({ ...jobSearchParams, radius: '101' })
      .then((res) => {
        setNationalJobs(res.data.premiumJobs.filter((job) => job.isNational));
      })
      .catch((err) => {
        if (err && err.message) {
          setSearchErrorMessage(err.message);
        } else {
          setSearchErrorMessage('Failed to search for national jobs');
        }
      });
  }

  function updatePremiumJob(updatedItem: types.JobInternalViewType) {
    const updatedLocalJobs = localJobs?.map((pj) => {
      return pj.id === updatedItem.id
        ? {
            ...pj,
            ...updatedItem,
          }
        : pj;
    });
    setLocalJobs(updatedLocalJobs);

    const updatedNationalJobs = nationalJobs?.map((pj) => {
      return pj.id === updatedItem.id
        ? {
            ...pj,
            ...updatedItem,
          }
        : pj;
    });

    setNationalJobs(updatedNationalJobs);
  }

  function updatePartnerJob(updatedItem: types.PartnerJobDetailViewType) {
    const updatedExternalJobs = !!partnerJobs
      ? partnerJobs.map((ej) => {
          return ej.jobKey === updatedItem.jobKey
            ? {
                ...ej,
                ...updatedItem,
              }
            : ej;
        })
      : [updatedItem];
    setPartnerJobs(updatedExternalJobs);
  }

  JobSearchContext.displayName = 'Job Search Context';

  return (
    <JobSearchContext.Provider
      value={{
        defaultMapCenter,
        jobSearchParams,
        searchJobs,
        localJobs,
        nationalJobs,
        partnerJobs,
        updatePremiumJob,
        updatePartnerJob,
        searchErrorMessage,
        updateJobSearchParams,
      }}
    >
      {props.children}
    </JobSearchContext.Provider>
  );
};

export default JobSearchContextWrapper;

export function isCsvJob(obj: any): obj is JobPostingCSVJobType {
  return 'jobId' in obj && 'occupationalCategory' in obj;
}

export function isPartnerCsvJob(obj: any): obj is JobPostingCSVPartnerType {
  return 'jobKey' in obj && !('distance' in obj);
}

export function isPartnerAuthJob(
  obj: any
): obj is types.PartnerJobDetailViewType {
  return 'jobKey' in obj && 'distance' in obj;
}

export function isDpAuthJob(obj: any): obj is types.JobInternalViewType {
  return 'appliedDate' in obj;
}
