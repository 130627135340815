import React, { useContext, useState } from 'react';
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { toAccountSettings, toSignOut } from '../../routes';
import { Link } from 'gatsby';
import _isEmpty from 'lodash/isEmpty';
import {
  UserContext,
  UserContextType,
  isEmployerType,
} from '../../context/UserContext';
import ProfilePicture from '../common/ProfilePicture';

const AuthenticatedDropdownMenu = () => {
  const { user } = useContext(UserContext) as UserContextType;
  const [userMenuIsOpen, setUserMenuIsOpen] = useState(false);

  function toggleUserMenu() {
    setUserMenuIsOpen(!userMenuIsOpen);
  }

  return (
    <ButtonDropdown isOpen={userMenuIsOpen} toggle={toggleUserMenu}>
      <DropdownToggle
        color="link"
        caret
        className="d-flex align-items-center text-black"
      >
        <div style={{ width: '45px', height: '45px' }} className="me-2">
          <ProfilePicture
            photoUrl={user?.photoThumbnailUrl}
            alt={user?.name}
            squarePhoto={isEmployerType(user)}
          />
        </div>
        <span className="d-none d-lg-inline text-wrap text-center mx-auto">
          {user?.name ?? 'Staff User'}
        </span>
      </DropdownToggle>
      <DropdownMenu className="w-100">
        <DropdownItem tag={Link} to={toAccountSettings}>
          Settings
        </DropdownItem>
        <DropdownItem divider />
        <DropdownItem tag={Link} to={toSignOut}>
          Sign Out
        </DropdownItem>
      </DropdownMenu>
    </ButtonDropdown>
  );
};

export default AuthenticatedDropdownMenu;
