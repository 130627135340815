import React, { useContext, useEffect, useState } from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';
import { isStaffProfileType, ProfileContext } from '../context/ProfileContext';
import OnOffSwitch from './common/OnOffSwitch';
import { FaGlobeAmericas, FaMapMarkerAlt } from 'react-icons/fa';
import agent from '../agent';
import analytics, {
  ENGAGEMENT_CATEGORY,
  PROFILE_PRIVATE_LABEL,
  PROFILE_PUBLIC_LABEL,
  PROFILE_RELOCATE_ACTION,
  PROFILE_VISIBILITY_ACTION,
} from '../analytics';
import FormWrapper from './common/FormWrapper';
import axios, { AxiosResponse } from 'axios';
import useLocalStorageState from 'use-local-storage-state';
import { isStaffType, UserContext } from '../context/UserContext';
import dayjs from 'dayjs';
import { StaffResumeViewType } from '../types/services-api';

const StaffProfileSettings = (props: {
  notify?: boolean;
  initialProfile: StaffResumeViewType;
  username: string;
}) => {
  const [profileBoostFormViewedDate, setProfileBoostFormViewedDate] =
    useLocalStorageState<{ [key: string]: string }>(
      'hasViewedProfileBoostSurvey',
      { defaultValue: {} }
    );
  const { user } = useContext(UserContext);
  const { profile, updateProfile } = useContext(ProfileContext);
  const [isPublic, setIsPublic] = useState(props.initialProfile.isPublic);
  const [isRelocate, setIsRelocate] = useState(props.initialProfile.isRelocate);
  const [isOpen, setIsOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmittingVisibility, setIsSubmittingVisibliity] = useState(false);
  const [isSubmittingRelocate, setIsSubmittingRelocate] = useState(false);

  useEffect(() => {
    if (
      !!props.username &&
      props.initialProfile?.isGoodToApply &&
      (!props.initialProfile.isPublic || !props.initialProfile.isRelocate) &&
      (!profileBoostFormViewedDate[props.username] ||
        dayjs(profileBoostFormViewedDate[props.username]).isBefore(
          dayjs().subtract(30, 'day')
        )) &&
      !isOpen
    ) {
      setIsOpen(true);
    }
  }, [profile]);

  useEffect(() => {
    if (isOpen && isStaffType(user)) {
      setProfileBoostFormViewedDate({
        [`${user.username}`]: dayjs().format('YYYY-MM-DD'),
      });
    }
  }, [isOpen]);

  function toggle() {
    setIsOpen(!isOpen);
  }

  function trackVisibility(isTrue: boolean) {
    analytics.Events.trackEvent({
      actionType: PROFILE_VISIBILITY_ACTION,
      category: ENGAGEMENT_CATEGORY,
      label: isTrue ? PROFILE_PUBLIC_LABEL : PROFILE_PRIVATE_LABEL,
    });
  }

  function trackRelocate(isTrue: boolean) {
    analytics.Events.trackEvent({
      actionType: PROFILE_RELOCATE_ACTION,
      category: ENGAGEMENT_CATEGORY,
      label: isTrue ? 'Yes' : 'No',
    });
  }

  function submitForm(e) {
    const { profileVisibilityNotify, willingToRelocateNotify } =
      e.target.elements;
    setIsSubmitting(true);
    axios
      .all([
        agent.Staff.updateVisibility({
          state: !!profileVisibilityNotify.checked,
        }),
        agent.Staff.updateRelocate({
          state: !!willingToRelocateNotify.checked,
        }),
      ])
      .then(
        axios.spread(
          (
            visibilityRes: AxiosResponse,
            willingToRelocateRes: AxiosResponse
          ) => {
            updateProfile({
              isPublic: visibilityRes.data.isPublic,
              isRelocate: willingToRelocateRes.data.isRelocate,
            });
            trackVisibility(visibilityRes.data.isPublic);
            trackRelocate(willingToRelocateRes.data.isRelocate);
            toggle();
            setIsSubmitting(false);
          }
        )
      )
      .catch((err) => {
        setErrorMessage(err.message);
        setIsSubmitting(false);
      });
  }

  if (props.notify) {
    return (
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalBody>
          <h3 className="fw-bold text-center mb-4">
            Let’s update your profile to get hired faster!
          </h3>
          <FormWrapper
            formId="staff-update-profile-modal-form"
            onClick={submitForm}
            onReset={toggle}
            disabled={isSubmitting}
          >
            <div
              className={`gap-3 align-items-center justify-content-between ${props.initialProfile?.isPublic ? 'd-none' : 'd-flex'}`}
            >
              <div>
                <p className="mb-0 fw-bolder fa-lg">
                  <FaGlobeAmericas className="text-info mb-1" /> Make Profile
                  Public
                </p>
                <p>Public profiles get hired 2x faster than private profiles</p>
              </div>
              <div style={{ minWidth: '100px' }}>
                <OnOffSwitch
                  id="profileVisibilityNotify"
                  name="profileVisibilityNotify"
                  defaultChecked={isPublic}
                  onChange={(e) => {
                    setIsPublic(!isPublic);
                  }}
                  label={isPublic ? 'Public' : 'Private'}
                />
              </div>
            </div>
            <div className="d-flex gap-3 align-items-center justify-content-between mb-3">
              <div>
                <p className="mb-0 fw-bolder fa-lg">
                  <FaMapMarkerAlt className="text-info mb-1" /> Willing to
                  Relocate
                </p>
                <p>Some employers may pay for relocation assistance.</p>
              </div>
              <div style={{ minWidth: '100px' }}>
                <OnOffSwitch
                  id="willingToRelocateNotify"
                  name="willingToRelocateNotify"
                  onChange={(e) => {
                    setIsRelocate(!isRelocate);
                  }}
                  defaultChecked={isRelocate}
                  label={isRelocate ? 'Yes' : 'No'}
                />
              </div>
            </div>
            {!!errorMessage && (
              <div className="alert alert-danger">{errorMessage}</div>
            )}
            <div className="d-flex justify-content-end gap-4">
              <Button color="light" type="reset">
                Cancel
              </Button>
              <Button
                color="primary"
                type="submit"
                form="staff-update-profile-modal-form"
              >
                Save Preferences
              </Button>
            </div>
          </FormWrapper>
        </ModalBody>
      </Modal>
    );
  } else if (isStaffProfileType(profile)) {
    return (
      <div id="staff-profile-settings-form">
        <div className="d-flex gap-3 align-items-center justify-content-between">
          <div>
            <p className="mb-0 fw-bolder fa-lg">
              <FaGlobeAmericas className="text-info mb-1" /> Make Profile Public
            </p>
            <p>Public profiles get hired 2x faster than private profiles</p>
          </div>
          <div style={{ minWidth: '100px' }}>
            <OnOffSwitch
              id="profileVisibility"
              name="profileVisibility"
              checked={profile.isPublic}
              onChange={(e) => {
                setIsSubmittingVisibliity(true);
                agent.Staff.updateVisibility({
                  state: e.target.checked,
                })
                  .then((res) => {
                    updateProfile({ isPublic: res.data.isPublic });
                    trackVisibility(res.data.isPublic);
                    setIsSubmittingVisibliity(false);
                  })
                  .catch((err) => {
                    setErrorMessage(err.message);
                    setIsSubmittingVisibliity(false);
                  });
              }}
              label={
                isSubmittingVisibility
                  ? '...Loading'
                  : profile.isPublic
                    ? 'Public'
                    : 'Private'
              }
            />
          </div>
        </div>
        <div className="d-flex gap-3 align-items-center justify-content-between mb-3">
          <div>
            <p className="mb-0 fw-bolder fa-lg">
              <FaMapMarkerAlt className="text-info mb-1" /> Willing to Relocate
            </p>
            <p>Some employers may pay for relocation assistance.</p>
          </div>
          <div style={{ minWidth: '100px' }}>
            <OnOffSwitch
              id="willingToRelocate"
              name="willingToRelocate"
              checked={profile.isRelocate}
              onChange={(e) => {
                setIsSubmittingRelocate(true);
                agent.Staff.updateRelocate({
                  state: e.target.checked,
                })
                  .then((res) => {
                    updateProfile({ isRelocate: res.data.isRelocate });
                    trackRelocate(res.data.isRelocate);
                    setIsSubmittingRelocate(false);
                  })
                  .catch((err) => {
                    setErrorMessage(err.message);
                    setIsSubmittingRelocate(false);
                  });
              }}
              label={
                isSubmittingRelocate
                  ? '...Loading'
                  : profile.isRelocate
                    ? 'Yes'
                    : 'No'
              }
            />
          </div>
        </div>
        {!!errorMessage && (
          <div className="alert alert-danger">{errorMessage}</div>
        )}
      </div>
    );
  } else {
    return null;
  }
};

export default StaffProfileSettings;
