import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  Row,
  UncontrolledAccordion,
} from 'reactstrap';
import { Link } from 'gatsby';
import { toBlog, toSignIn, toGetStarted, toBlogPostDetail } from '../../routes';
import { FaBars, FaArrowRight } from 'react-icons/fa';
import dayjs from 'dayjs';
import { BlogPostFeaturedListType } from '../../types/wordpress';
import { NavCategoriesType } from './Layout';

const NavSlideover = (props: { navCategories: NavCategoriesType }) => {
  const { unauthenticatedPosts } = useStaticQuery<{
    unauthenticatedPosts: BlogPostFeaturedListType;
  }>(blogQuery);

  const [offcanvasIsOpen, setOffcanvasIsOpen] = useState(false);

  function toggleOffcanvas() {
    setOffcanvasIsOpen((prev) => !prev);
  }
  return (
    <>
      <Button
        color="link"
        onClick={toggleOffcanvas}
        className={`d-lg-none text-black pe-0`}
        aria-label="Main Menu"
      >
        <FaBars className="dp-font-lg" />
      </Button>
      <Offcanvas
        direction="end"
        isOpen={offcanvasIsOpen}
        toggle={toggleOffcanvas}
      >
        <OffcanvasHeader toggle={toggleOffcanvas}></OffcanvasHeader>
        <OffcanvasBody className="bg-solids-off-white-50 px-0 pt-0">
          <Nav vertical>
            <UncontrolledAccordion
              toggle={() => {}}
              flush
              defaultOpen={['1']}
              stayOpen
              className="mb-5"
            >
              {props.navCategories.map((category, categoryIndex) => {
                return (
                  <AccordionItem key={`category-${categoryIndex}`}>
                    <AccordionHeader targetId={`accordian-${categoryIndex}`}>
                      <p className="h2">{category.title}</p>
                    </AccordionHeader>
                    <AccordionBody accordionId={`accordian-${categoryIndex}`}>
                      <p className="h3">{category.mainNav.header}</p>
                      <p>{category.mainNav.description}</p>
                      {category.mainNav.links.map((link, linkIndex) => {
                        return (
                          <NavItem
                            key={`mobile-${category.title}-main-link-${linkIndex}`}
                          >
                            <NavLink
                              tag={Link}
                              to={link.route}
                              className="link-primary"
                              onClick={toggleOffcanvas}
                            >
                              {link.label}
                            </NavLink>
                          </NavItem>
                        );
                      })}
                      {!!category.mainNav.cta && (
                        <Button
                          className="mt-3"
                          color="primary"
                          tag={Link}
                          to={category.mainNav.cta.route}
                          onClick={toggleOffcanvas}
                        >
                          {category.mainNav.cta.label}
                        </Button>
                      )}
                      {!!category.featureNav && (
                        <>
                          <p className="mt-5 h3">
                            {category.featureNav.header}
                          </p>
                          {category.featureNav.links.map((link, linkIndex) => {
                            return (
                              <NavItem
                                key={`mobile-${category.title}-feature-link-${linkIndex}`}
                                className="my-3"
                              >
                                <NavLink
                                  tag={Link}
                                  to={link.route}
                                  className="link-black"
                                  onClick={toggleOffcanvas}
                                >
                                  <Container fluid className="px-0">
                                    <Row>
                                      <Col xs="auto" className="ps-0">
                                        {link.icon}
                                      </Col>
                                      <Col className="d-flex align-items-center">
                                        <p className="mb-0">{link.header}</p>
                                      </Col>
                                      <Col xs="auto" className="ms-auto">
                                        <FaArrowRight className="link-primary" />
                                      </Col>
                                    </Row>
                                  </Container>
                                </NavLink>
                              </NavItem>
                            );
                          })}
                        </>
                      )}
                      {!!category.blogNav && (
                        <>
                          <div className="mt-5 d-flex justify-content-between align-items-center">
                            <p className="h3">Our Blog</p>
                            <div>
                              <Button
                                tag={Link}
                                to={toBlog}
                                className="ms-auto"
                                color="primary"
                                onClick={toggleOffcanvas}
                              >
                                View all blog posts
                              </Button>
                            </div>
                          </div>
                          {unauthenticatedPosts.nodes.map((blog) => {
                            const {
                              featuredImage: {
                                node: { altText, localFile },
                              },
                              id,
                              title,
                              date,
                              slug,
                            } = blog;
                            const gatsbyImage = getImage(localFile);
                            return (
                              <NavItem
                                key={`mobile-blog-link-${id}`}
                                className="my-3"
                              >
                                <NavLink
                                  className="link-black px-0"
                                  tag={Link}
                                  to={toBlogPostDetail(slug)}
                                  onClick={toggleOffcanvas}
                                >
                                  <Card>
                                    <CardHeader className="p-0">
                                      {!!gatsbyImage && (
                                        <GatsbyImage
                                          loading="lazy"
                                          image={gatsbyImage}
                                          alt={altText}
                                          style={{
                                            pointerEvents: 'none',
                                          }}
                                        />
                                      )}
                                    </CardHeader>
                                    <CardBody>
                                      <h4 className="mb-0">{title}</h4>
                                      <p className="mb-0 d-flex justify-content-between">
                                        <small>
                                          Posted{' '}
                                          {dayjs(date).format('MMMM DD, YYYY')}
                                        </small>
                                        <span className="text-primary">
                                          ...Read
                                        </span>
                                      </p>
                                    </CardBody>
                                  </Card>
                                </NavLink>
                              </NavItem>
                            );
                          })}
                        </>
                      )}
                    </AccordionBody>
                  </AccordionItem>
                );
              })}
            </UncontrolledAccordion>
            <div className="d-flex flex-column px-3">
              <Button
                className="mb-4"
                color="primary"
                tag={Link}
                to={toGetStarted}
                onClick={toggleOffcanvas}
              >
                Create Account
              </Button>
              <Button
                color="primary"
                outline
                tag={Link}
                to={toSignIn}
                onClick={toggleOffcanvas}
              >
                Sign In
              </Button>
            </div>
          </Nav>
        </OffcanvasBody>
      </Offcanvas>
    </>
  );
};

export default NavSlideover;

export const blogQuery = graphql`
  query {
    unauthenticatedPosts: allWpPost(
      limit: 3
      filter: {
        categories: { nodes: { elemMatch: { databaseId: { eq: 4 } } } }
      }
      sort: { date: DESC }
    ) {
      nodes {
        ...BlogPostFields
      }
    }
  }
`;
