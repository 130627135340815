import React from 'react';
import { FaInfoCircle } from 'react-icons/fa';

const OnOffSwitch = (props) => {
  const { defaultChecked, checked, label, name, onChange, helperText } = props;

  return (
    <div className="d-flex">
      <input
        defaultChecked={defaultChecked}
        checked={checked}
        onChange={onChange}
        className="react-switch-checkbox"
        id={name}
        name={name}
        type="checkbox"
      />
      <label className="react-switch-label" htmlFor={name}>
        <span className={`react-switch-button`} />
      </label>
      <label className="ms-2">{label}</label>
      {!!helperText && (
        <p className="mb-0">
          <small className="d-flex align-items-center">
            <FaInfoCircle className="text-secondary me-2" /> {helperText}
          </small>
        </p>
      )}
    </div>
  );
};

export default OnOffSwitch;
