import React, { PropsWithChildren } from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { Navbar, NavbarBrand } from 'reactstrap';
import { rootPath } from '../../routes';
import FlyoutMenu from './FlyoutMenu';
import SearchModal from '../../components/search/SearchModal';
import { NavCategoriesType } from './Layout';
import BannerAnnouncement from '../marketing/BannerAnnouncement';
import MarketingPromotion from '../marketing/MarketingPromotion';

const TopNavbar = (
  props: PropsWithChildren<{
    navCategories: NavCategoriesType;
    bannerHeight: number;
  }>
) => {
  return (
    <>
      <BannerAnnouncement />
      <Navbar
        color={'white'}
        expand="md"
        className={
          'position-relative py-0 px-3 d-flex w-100 align-items-stretch'
        }
        style={{ height: '85px' }}
      >
        <NavbarBrand
          tag={Link}
          to={rootPath}
          className="h-100 me-0 d-flex align-items-center"
          style={{ width: '69px', height: '44px' }}
        >
          <StaticImage
            loading="eager"
            src="../../images/dentalpost-logo-on-light-bg.png"
            placeholder="blurred"
            layout="constrained"
            alt="DentalPost Logo"
            width={69}
            height={44}
          />
        </NavbarBrand>
        <FlyoutMenu navCategories={props.navCategories} />
        <div className="d-flex gap-3 ms-auto me-lg-3">
          <MarketingPromotion bannerHeight={props.bannerHeight} />
          <SearchModal />
        </div>
        {props.children}
      </Navbar>
    </>
  );
};

export default TopNavbar;
