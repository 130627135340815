import React, { useEffect, useGlobal, useState } from 'reactn';
import { PropsWithChildren, useContext } from 'react';
import types from '../types/services-api';
import agent from '../agent';
import { GlobalContext } from './GlobalContext';
import analytics, {
  ADD_TO_CART_ACTION,
  ENGAGEMENT_CATEGORY,
  REMOVE_FROM_CART_ACTION,
} from '../analytics';
import dayjs from 'dayjs';

export type SkuType = 'BASIC' | 'JOB' | 'INV' | 'NAT' | 'JOBSUB' | 'INVSUB';

export type PurchaseContextType = {
  appliedDiscount?: {
    code: string;
    message: string;
    productCode: string;
  };
  cart: ProductCartType;
  resetCart: () => void;
  updateCart: (productCode: SkuType, quantity: number) => void;
  products:
    | {
        [key: string]: ProductFormattedType;
      }
    | undefined;
  getBestDiscount: (props: {
    productCode: SkuType;
    cartUpdateQuantity?: number;
    discounts?: { [key: string]: types.ProductDiscountType[] };
  }) => types.ProductDiscountType | void;
  couponErrorMessage: string;
  getCatalog: (discountCode: string, sku?: SkuType) => void;
  mapAnalyticsPayload: (
    sku: SkuType,
    index: number,
    addOn: boolean
  ) => { [key: string]: any } | undefined;
};

type ProductCombinedType = types.ProductSkuType & types.ProductDiscountType;
export type ProductFormattedType = ProductCombinedType | types.ProductSkuType;
export function isDiscountedType(obj: any): obj is ProductCombinedType {
  return !!obj && 'discountCode' in obj;
}

export type ProductCartType = {
  BASIC: number;
  JOB: number;
  INV: number;
  NAT: number;
  JOBSUB: number;
  INVSUB: number;
};

const STATIC_CART_DATA: ProductCartType = {
  BASIC: 1,
  JOB: 1,
  INV: 0,
  NAT: 0,
  JOBSUB: 1,
  INVSUB: 0,
};

export const PurchaseContext = React.createContext<PurchaseContextType>({
  appliedDiscount: undefined,
  cart: STATIC_CART_DATA,
  resetCart: () => {},
  updateCart: (productCode: SkuType, quantity: number) => {},
  products: undefined,
  getBestDiscount: (props: {
    productCode: string;
    cartUpdateQuantity?: number;
    apiDiscounts?: { [key: string]: types.ProductDiscountType[] };
  }) => {},
  couponErrorMessage: '',
  getCatalog: (discountCode?: string) => {},
  mapAnalyticsPayload: (sku: SkuType, index: number, addOn: boolean) =>
    undefined,
});

export function isOneMonthPromo(product) {
  return (
    isDiscountedType(product) &&
    product.discountFee < product.fee &&
    dayjs(product.endDate).isBefore(dayjs().add(30, 'day'))
  );
}

const PurchaseContextWrapper = (props: PropsWithChildren) => {
  const [isAuthenticated] = useGlobal('isAuthenticated');
  const [isEmployer] = useGlobal('isEmployer');
  const { urlParams } = useContext(GlobalContext);
  const [catalog, setCatalog] = useState<
    | {
        [key: string]: types.ProductSkuType;
      }
    | undefined
  >(undefined);
  const [couponErrorMessage, setCouponErrorMessage] = useState('');
  const [appliedDiscount, setAppliedDiscount] = useState<{
    code: string;
    message: string;
    productCode: string;
  }>();
  const [availableDiscounts, setAvailableDiscounts] = useState<{
    [key: string]: types.ProductDiscountType[];
  }>({});

  const [products, setProducts] = useState<
    | {
        [key: string]: types.ProductSkuType | ProductFormattedType;
      }
    | undefined
  >(undefined);

  const [cart, setCart] = useState<ProductCartType>(STATIC_CART_DATA);
  const [cartUpdateTracking, setCartUpdateTracking] = useState<
    | { productCode: string; quantity: number; action: 'add' | 'remove' }
    | undefined
  >(undefined);

  useEffect(() => {
    getCatalog(urlParams.discountCode ?? appliedDiscount?.code ?? '');
  }, [isAuthenticated, urlParams.discountCode]);

  function getCatalog(discountCode: string) {
    setCouponErrorMessage('');
    setProducts(undefined);
    if (isEmployer) {
      getAuthCatalog(discountCode);
    } else {
      getPublicCatalog(discountCode);
    }
  }

  function getPublicCatalog(discountCode: string) {
    agent.Jobs.publicCatalog(discountCode)
      .then((res) => {
        formatData(res.data, discountCode);
      })
      .catch((err) => {
        setCouponErrorMessage(err.message);
      });
  }

  function getAuthCatalog(discountCode: string) {
    agent.Jobs.catalog(discountCode)
      .then((res) => {
        formatData(res.data, discountCode);
      })
      .catch((err) => {
        setCouponErrorMessage(err.message);
      });
  }

  function formatData(
    apiResponse: types.CatalogViewType,
    discountCode: string
  ) {
    const apiCatalog = apiResponse.products;
    const apiDiscounts = formatDiscountData(apiResponse.discounts);
    const displayDiscounts = apiResponse.discounts.filter(
      (obj) =>
        obj.discountCode.toLowerCase() === discountCode.toLowerCase() ||
        !discountCode
    );
    if (!!displayDiscounts[0]) {
      setAppliedDiscount({
        code: displayDiscounts[0].discountCode,
        message: displayDiscounts[0].displayPhrase,
        productCode: displayDiscounts[0].productCode,
      });
    } else {
      setAppliedDiscount(undefined);
    }
    setAvailableDiscounts(apiDiscounts);
    setCatalog(formatProductData(apiCatalog));
    const formattedProducts = apiCatalog.map((product) => {
      if (!!apiDiscounts[product.productCode]) {
        const formattedProduct: ProductFormattedType = {
          ...product,
          ...getBestDiscount({
            productCode: product.productCode,
            discounts: formatDiscountData(displayDiscounts),
            cartUpdateQuantity:
              cart[product.productCode] > 0 ? cart[product.productCode] : 1,
          }),
        };
        if (cart[product.productCode] === 0) {
          updateCart(product.productCode, 1);
        }
        return formattedProduct;
      } else {
        return product;
      }
    });
    setProducts(formatProductData(formattedProducts));
  }

  function formatProductData(productArray: ProductFormattedType[]): {
    [key: string]: types.ProductSkuType;
  } {
    const formattedData = productArray.reduce(
      (acc, item) => ({
        ...acc,
        [item.productCode]: item,
      }),
      {}
    );
    return formattedData;
  }

  function getBestDiscount(props: {
    productCode: string;
    cartUpdateQuantity?: number;
    discounts?: { [key: string]: types.ProductDiscountType[] };
  }) {
    const { productCode, cartUpdateQuantity } = props;
    const discountsApplied = props.discounts
      ? props.discounts
      : availableDiscounts;
    const quantity = cartUpdateQuantity
      ? cartUpdateQuantity
      : cart[productCode];
    if (!!discountsApplied[props.productCode]) {
      const bestDiscount = discountsApplied[props.productCode].reduce(
        (
          prev: types.ProductDiscountType | undefined,
          curr: types.ProductDiscountType
        ) => {
          if (
            quantity >= curr.minimumQuantity &&
            quantity <= curr.maximumQuantity &&
            (!prev ||
              curr.discountFee < prev.discountFee ||
              curr.discountActivationFee < prev.discountActivationFee)
          ) {
            return curr;
          } else {
            return prev;
          }
        },
        undefined
      );
      return bestDiscount;
    }
  }

  function formatDiscountData(apiResponse: types.ProductDiscountType[]): {
    [key: string]: types.ProductDiscountType[];
  } {
    const formattedData = apiResponse.reduce((acc, item) => {
      return {
        ...acc,
        [item.productCode]: [...(acc[item.productCode] || []), item],
      };
    }, {});
    return formattedData;
  }

  const skuToQtyMap = (lineItemSku: string, addOn: boolean) => {
    //set a min quantity of 1 for displaying optional add-on item values for "view_items"/"add_to_cart", etc events
    switch (lineItemSku) {
      case 'INV':
        if (addOn) {
          return (cart.INV > 0 ? cart.INV : 1) * 50;
        } else {
          return (cart.INV > 0 ? cart.INV : 1) * 50 * cart.JOB;
        }
      case 'INVSUB':
        return (cart.INVSUB > 0 ? cart.INVSUB : 1) * 50 * cart.JOBSUB;
      case 'NAT':
        if (addOn) {
          return cart.NAT > 0 ? cart.NAT : 1;
        } else {
          return cart.JOB;
        }
      default:
        return cart[`${lineItemSku}`];
    }
  };

  function mapAnalyticsPayload(sku: string, index: number, addOn: boolean) {
    if (!!products) {
      const product = products[sku];
      const payload = {
        index: index,
        item_id: sku,
        item_brand: 'DentalPost',
        item_name: product.description,
        coupon: isDiscountedType(product) ? product.discountCode : '',
        activationFee:
          sku === 'JOBSUB'
            ? isDiscountedType(product)
              ? product.discountActivationFee
              : product.activationFee
            : 0,
        discount: isDiscountedType(product)
          ? (product.fee +
              product.activationFee -
              (product.discountFee + product.discountActivationFee)) *
            skuToQtyMap(sku, addOn)
          : 0,
        item_category: sku.includes('SUB') ? 'Subscription' : '30-Day Posting',
        price: isDiscountedType(product) ? product.discountFee : product.fee,
        quantity: skuToQtyMap(sku, addOn),
      };

      return payload;
    }
  }

  useEffect(() => {
    if (
      !!cartUpdateTracking &&
      cart[cartUpdateTracking.productCode] === cartUpdateTracking.quantity
    ) {
      const { productCode, quantity } = cartUpdateTracking;

      if (!!products) {
        const product = products[`${productCode}`];
        const analyticsPayload = {
          currency: 'USD',
          value: product.fee * quantity + (product.activationFee ?? 0),
          items: [
            {
              ...mapAnalyticsPayload(productCode, 0, false),
              item_list_id: 'purchase_form',
              item_list_name: 'Purchase Form',
            },
          ],
        };

        if (cartUpdateTracking.action === 'add') {
          analytics.Events.trackEvent({
            actionType: ADD_TO_CART_ACTION,
            category: ENGAGEMENT_CATEGORY,
            payload: analyticsPayload,
          });
        } else if (cartUpdateTracking.action === 'remove') {
          analytics.Events.trackEvent({
            actionType: REMOVE_FROM_CART_ACTION,
            category: ENGAGEMENT_CATEGORY,
            payload: analyticsPayload,
          });
        }
      }
    }
  }, [cart, cartUpdateTracking]);

  function updateCart(productCode: string, quantity: number) {
    if (quantity === 0 || (productCode === 'INVSUB' && quantity === 1)) {
      setCartUpdateTracking({
        productCode,
        quantity,
        action: 'remove',
      });
    } else if (quantity > 0) {
      setCartUpdateTracking({ productCode, quantity, action: 'add' });
    }
    setCart({
      ...cart,
      [productCode]: quantity,
    });
    //recalculate best discount when cart quantities change
    if (!!catalog && !!availableDiscounts) {
      const updatedProduct = {
        ...catalog[productCode],
        ...getBestDiscount({
          productCode: productCode,
          cartUpdateQuantity: quantity,
          discounts: availableDiscounts,
        }),
      };
      setProducts({
        ...products,
        [productCode]: updatedProduct,
      });
    }
  }

  function resetCart() {
    setCart(STATIC_CART_DATA);
  }

  //for DevTools to display context name
  PurchaseContext.displayName = 'Purchase Context';

  return (
    <PurchaseContext.Provider
      value={{
        appliedDiscount,
        cart,
        resetCart,
        updateCart,
        products,
        getBestDiscount,
        couponErrorMessage,
        getCatalog,
        mapAnalyticsPayload,
      }}
    >
      {props.children}
    </PurchaseContext.Provider>
  );
};

export default PurchaseContextWrapper;
